.FrameTitle {
  font-family: "Poppins-ExtraBold";
  letter-spacing: 1px;
  font-size: 10px;
  cursor: pointer;
  color: #b7b7b7;
}

.FrameContainer {
  border-left: 3px solid hsl(var(--input));
  margin-top: 8px !important;
  padding-left: 23px;
  margin-left: 20px !important;
}
