* {
  outline: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

@font-face {
  font-family: "Hikou-Outline";
  src: url("../../assets/hikou/Hikou\ Outline.otf") format("opentype");
}
@font-face {
  font-family: "Hikou";
  src: url("../../assets/hikou/Hikou\ Regular.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("../../assets/poppins/Poppins-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Light";
  src: url("../../assets/poppins/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("../../assets/poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("../../assets/montserrat/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../assets/montserrat/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "BlinkMacSystemFont-Bold";
  src: url("../../assets/blinkMacSystemFont/BlinkMacSystemFont-Bold.otf") format("opentype");
  font-size: 2.25rem;
  line-height: 2.5rem;
  letter-spacing: -0.025em;
  font-weight: 700;
}
