.draggable-block-menu {
    border-radius: 4px;
    padding: 2px 1px;
    cursor: grab;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    will-change: transform;
  }
  
  .draggable-block-menu .icon {
    width: 16px;
    height: 16px;
    opacity: 0.3;
    background-image: url(./images/icons/draggable-block-menu.svg);
  }
  
  .draggable-block-menu:active {
    cursor: grabbing;
  }
  
  .draggable-block-menu:hover {
    background-color: #efefef;
  }
  
  .draggable-block-target-line {
    pointer-events: none;
    background: deepskyblue;
    height: 4px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    will-change: transform;
  }